import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useFormik, Form, FormikProvider } from 'formik';

function FeatureForm({ mutate, editedFeature, editFeatureMutate }) {
  const queryClient = useQueryClient();

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      if (editedFeature) {
        editFeatureMutate({ featureId: editedFeature._id, ...values });
      } else {
        mutate(values);
      }
    },
  });

  useEffect(() => {
    if (editedFeature) {
      formik.setFieldValue('name', editedFeature.name || '');
      formik.setFieldValue('description', editedFeature.description || '');
    }
  }, [editedFeature]);

  const handleChange = (event) => {
    formik.setFieldValue('duration', event.target.value);
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <TextField
            fullWidth
            label="Description"
            multiline
            rows={4}
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          // disabled={isLoading || risLoading}
          // loading={isSubmitting || isLoading || risLoading}
          >
            {!editedFeature ? 'ADD' : 'UPDATE'}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default FeatureForm;
