import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { baseUrl } from '../constant/config';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import AdsMoreMenu from '../sections/@dashboard/ads/AdsMoreMenu';
import AdsPackageForm from '../components/Ads/AdsPackageForm';
import DropdownMenu from '../components/Ads/AdsFilterMenu';

function Ads() {
  const [filterPackageId, setFilterPackageId] = useState(null);
  const [adPackage, setAdPackage] = useState([]);
  // const [ads, setAds] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setSelectedUser(null);
    setOpen(true);
  };

  const { data: ads, isLoading, isError, error } = useQuery(
    ['myads'],
    async () => {
      const url = `${baseUrl}/api/ads`;
      const { data } = await axios.get(url);
      return data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  console.log(ads);
  if (isLoading) {
    return <p>Loading....</p>;
  }

  const TABLE_HEAD = [
    { id: 'username', label: 'Username', alignRight: false },
    // { id: 'company', label: 'Email', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'duration', label: 'Duration(sec)', alignRight: false },
    { id: 'amount', label: 'Amount($)', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'noImages', label: 'No of Images', alignRight: false },
    // { id: 'isVerified', label: 'Verified', alignRight: false },
    // { id: '' },
  ];

  return (
    <Page title="Ads">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Ads
          </Typography>
          {/* <Box sx={{ display: 'flex', gridGap: '10px' }}>
            <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              New Ads Package
            </Button>
          </Box> */}
        </Stack>
        <Card>
          <DropdownMenu />
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      // align={headCell.alignRight ? 'right' : 'left'}
                      // sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                      // hideSortIcon
                      // active={orderBy === headCell.id}
                      // direction={orderBy === headCell.id ? order : 'asc'}
                      // onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {/* {orderBy === headCell.id ? (
                          <Box sx={{ ...visuallyHidden }}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null} */}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {ads &&
                  ads?.length===0 ?<p style={{color:'#2065D1',margin:'10px'}}>No Ads Found</p>:
                  ads?.map((ad, index) => {
                    const ads = ad.status;
                    let adsValue = '';
                    if (ads === '0') {
                      adsValue = 'Rejected';
                    } else if (ads === '2') {
                      adsValue = 'Completed';
                    } else if (ads === '3') {
                      adsValue = 'Approved';
                    } else {
                      adsValue = 'Recently Added';
                    }
                    return (
                      <>
                        <TableRow hover tabIndex={-1} role="checkbox">
                          <TableCell key={index}>{`${ad?.firstName} ${ad?.lastName}`}</TableCell>
                          <TableCell key={index}>{adsValue}</TableCell>
                          <TableCell key={index}>{ad?.email}</TableCell>
                          <TableCell key={index}>{ad?.duration}</TableCell>
                          <TableCell key={index}>{ad?.package}</TableCell>
                          <TableCell key={index}>{ad?.phone}</TableCell>
                          <TableCell key={index}>{ad?.adsImages.length}</TableCell>
                          <TableCell key={index}>
                            <Box key={index}>
                              <Link to={`/dashboard/adsDetails/${ad._id}`}>
                                <Button>Click Me to View</Button>
                              </Link>
                            </Box>
                          </TableCell>
                          <TableCell key={index}>
                            <AdsMoreMenu user={ads} setSelectedUser={setSelectedUser} handleOpen={handleOpen} />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>

              {/* <TableBody>
                {ads &&
                  ads?.length &&
                  ads?.map((ad, index) => {
                    const ads = ad.status;
                    let adsValue = '';
                    if (ads === '0') {
                      adsValue = 'Rejected';
                    } else if (ads === '2') {
                      adsValue = 'Completed';
                    } else if (ads === '3') {
                      adsValue = 'Approved';
                    } else {
                      adsValue = 'Recently Added';
                    }
                    return (
                      <>
                        <TableRow hover tabIndex={-1} role="checkbox">
                          <TableCell key={index}>{`${ad?.firstName} ${ad?.lastName}`}</TableCell>
                          <TableCell key={index}>{adsValue}</TableCell>
                          <TableCell key={index}>{ad?.email}</TableCell>
                          <TableCell key={index}>{ad?.adsPackageId}</TableCell>
                          <TableCell key={index}>{ad?.phoneNumber}</TableCell>
                          <TableCell key={index}>{ad?.adsImages.length}</TableCell>
                          <TableCell key={index}>
                            <Box>
                              <Link to={`/dashboard/adsDetails/${ad._id}`}>
                                <Button>Click Me to View</Button>
                              </Link>
                            </Box>
                          </TableCell>
                          <TableCell key={index}>
                            <AdsMoreMenu user={ads} setSelectedUser={setSelectedUser} handleOpen={handleOpen} />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody> */}
            </Table>
          </TableContainer>
        </Card>
        {/* <Dialog onClose={handleClose} open={open}>
          <Box sx={{ m: 0, p: 4, minWidth: 320 }}>
            <Typography id="modal-modal-title" variant="h5" component="h5" textAlign={'center'} mb="20px">
              {'Add New Package'}
            </Typography>
            <Box sx={{ width: '100%' }}>
              <AdsPackageForm mutate={mutate} />
            </Box>
          </Box>
        </Dialog> */}
      </Container>
    </Page>
  );
}

export default Ads;
