import { LoadingButton } from '@mui/lab'
import { Container, Stack, TextField, Typography } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-router-dom'
import axios from 'axios'
import * as Yup from 'yup';
import { baseUrl } from '../constant/config'
import Page from '../components/Page'
// material



export const TermsConditions = () => {
  const SendData=(values)=>{
    axios.post(`${baseUrl}/api/tc/create`,{"description":values.description}).then((res)=>{
      if(res.status===200){
        console.log("Response",res.data.data)
        console.log("")
        formik.setFieldValue("description",res.data.data.description)
      }
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    axios.get(`${baseUrl}/api/tc/tc`).then((res)=>{
      console.log("Response",res.data)
      formik.setFieldValue("description",res.data.data)  
      }).catch((err)=>{
      console.log(err)
    })
  },[])

  const termconditionschema=Yup.object().shape({
    description:Yup.string().required("description is required")
  })

const formik=useFormik({
  initialValues:{
    description:""
  },
  validationSchema:termconditionschema,
  onSubmit:(values,action)=>{
    SendData(values)
    action.resetForm()
  }
})


  return (
    <Page title="Terms & Conditions">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Terms & Conditions
                    </Typography>
                </Stack>
            </Container>
            <Container>
            {/* <FormikProvider value={formik}> */}
                    {/* <Form autoComplete="off" onSubmit={formik.handleSubmit} noValidate> */}
                        <Stack spacing={3}>
                       

                            <TextField
                                fullWidth
                                label="Description"
                                multiline
                                rows={5}
                                name='description'
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              
                            />
                            {
                              formik.touched.description && <p className='text-danger'>{formik.errors.description}</p>
                            }

                         
                            <LoadingButton
                              
                                size="large"
                                type="submit"
                                onClick={formik.handleSubmit}
                                variant="contained"
                            //     disabled={isLoading}
                            //     loading={isLoading || AboutLoading}
                            >
                                Save
                            </LoadingButton>
                        </Stack>
                  
            
            </Container>
        </Page>
  )
}
