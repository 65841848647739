// ----------------------------------------------------------------------
import { subFolder } from "../constant/config";

const account = {
  displayName: 'Admin',
  email: 'demo@minimals.cc',
  photoURL: `${subFolder}/static/mock-images/avatars/logo.png`,
};

export default account;
