import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import Page from '../Page';
import { baseUrl } from '../../constant/config';

function AdsDetails() {
  // const [ad, setAd] = useState();
  // const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  // console.log('Id', id);

  // const getAd = async () => {
    // axios
    //   .get(`${baseUrl}/api/ads/getAd/${id}`)
    //   .then((res) => {
    //     // console.log('res>>', res.data.resAd);
    //     setAd(res.data.resAd);
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log('ERROR', error);
    //     setIsLoading(false);
    //   });
    const {data:ad,isLoading,isError,error}=useQuery( ['adsdeetails'],
    async () => {
      const url = `${baseUrl}/api/ads/getAd/${id}`;
      const { data } = await axios.get(url);
      console.log("rda",data)
      return data.resAd;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
      
    
    )
  // };
  // console.log('AD STATE', ad);
  const TABLE_HEAD = [
    { id: 'adsId', label: 'Title', alignRight: false },
    { id: 'images', label: 'Images', alignRight: false },
  ];


  if(isLoading){
    return <p>Loading....</p>
  }
  if(isError){
    return <p>{error.message}</p>
  }


  return (
    <Page title="Ads Details">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Ads Details
          </Typography>
        </Stack>
        <Card>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      // align={headCell.alignRight ? 'right' : 'left'}
                      // sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                      // hideSortIcon
                      // active={orderBy === headCell.id}
                      // direction={orderBy === headCell.id ? order : 'asc'}
                      // onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {/* {orderBy === headCell.id ? (
                          <Box sx={{ ...visuallyHidden }}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null} */}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
             
                <TableBody>
                  <TableRow hover tabIndex={-1} role="checkbox">
                    <TableCell>{ad?.title}</TableCell>
                    <TableCell
                        className="ads-dtl-img"
                        style={{ "display":'flex',"gap":"2px"}}
                        >
                    {ad?.adsImages.map((img, index) => {
                      return (
                      
                       
                          
                             <div className='viwAddInrTxt' style={{"margin":"5px"}}>
                        <img
                          key={index}
                          src={`${baseUrl}/${img.image}`}
                          alt=""
                          height={200}
                          width={200}
                          className="img-fluid"
                          style={{"objectFit":"cover"}}
                        />
                        <h6 style={{"fontSize":"20px", "color":"#2065D1", "textTransform": "capitalize",}}> {img?.imageOrientation}</h6>
                        </div>
                           
                           );
                          })}
                          </TableCell>
                  </TableRow>
                </TableBody>
             
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
}

export default AdsDetails;
