import { LoadingButton } from '@mui/lab';
// material
import { IconButton, InputAdornment, Link, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
// component
import { useMutation } from 'react-query';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthState } from '../../../context/auth';
import Iconify from '../../../components/Iconify';
import { baseUrl } from '../../../constant/config';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState('');

  const { mutate, isLoading } = useMutation(async (variables) => {
    const { data } = await axios.post(
      `${baseUrl}/api/admin/login`,

      variables
    );
    return data;
  });

  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    username: Yup.string().required('USername is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      // email: '',
      username: '',
      password: '',
      // remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (d) => {
      console.log(d);
      mutate(d, {
        onSuccess: (d) => {
          console.log(d);
          localStorage.setItem('admin_token', d?.data?.accessToken);
          axios.defaults.headers.common.Authorization = `${d?.data?.accessToken}`;
          window.location.assign('/dashboard/user');
          // navigate('/dashboard/user', { replace: true });
        },
        onError: (e) => {
          console.log(e);
          setIsError(e?.response?.data?.message);
        },
      });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} onChange={() => setIsError('')}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            label="Username"
            {...getFieldProps('username')}
            error={Boolean((touched.username && errors.username) || isError)}
            helperText={isError || (touched.username && errors.username)}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean((touched.password && errors.password) || isError)}
            helperText={isError || (touched.password && errors.password)}
          />
        </Stack>

        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
          onClick={() => navigate('/forgetPassword')}
        >
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
            Forgot password?
          </Link>
        </Stack> */}

        <LoadingButton fullWidth sx={{ mt: '10px' }} size="large" type="submit" variant="contained" loading={isLoading}>
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
