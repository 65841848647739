/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/order */
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Box, FormControl, InputLabel, Select, MenuItem, Input, Autocomplete } from '@mui/material';
import { AuthState } from '../context/auth';
// import DateTimePicker from 'react-datetime-picker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { baseUrl } from '../constant/config';
import axios from 'axios';

function DebateForm({ mutate, editedDebate, editDebateMutate }) {
  const { topics } = AuthState();

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    startDate: Yup.string().required('Start Date is required'),
    duration: Yup.number().required('Duration is required'),
    topic: Yup.string().required('Topic is required'),
    imageUrl: Yup.string().required('Image Url is required'),
    bufferTime: Yup.number().required('Buffer Time is required'),
  });

  console.log("editedDebate", editedDebate);

  const [selectedAds, setSelectedAds] = useState([]);
  // const arrayOfId = ['64bc19d3506d50d79440c311', '64bc0be78db7af07a433053e', '64be08a854a4114a6a234178'];

  const handleAdsChange = (newValue) => {
    setSelectedAds(newValue);
  };
  const formik = useFormik({
    initialValues: {
      title: editedDebate?.title || '',
      description: editedDebate?.description || '',
      startDate: editedDebate?.startDate ? new Date(editedDebate?.startDate) : new Date(),
      duration: editedDebate?.duration || 15,
      topic: editedDebate?.topic || '',
      imageUrl: editedDebate?.imageUrl || '',
      adId: JSON.stringify(selectedAds),
      bufferTime: editedDebate?.bufferTime || '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      //  console.log(values);
      const formData = new FormData();
      formData.append('file', avatar);
      Object.entries(values).forEach(([key, value]) => {
        if (key === 'adId') {
          if (selectedAds?.length) {
            formData.append(key, JSON.stringify(selectedAds));
          } else {
            formData.append(key, JSON.stringify([]));
          }
        } else {
          formData.append(key, value);
        }
      });
      if (editedDebate) {
        editDebateMutate({
          debateId: editedDebate._id,
          formData,
        });
      } else {
        mutate(formData);
      }
    },
  });
  const [avatar, setAvatar] = React.useState(null);
  const [dimg, setdimg] = React.useState(null);
  const [adsList, setAdsList] = useState([]);
  const onChangeImages = (event) => {
    console.log("files", event.target.files);
    if (event.target.files) {
      if (event.target.files[0].size > 1024 * 1024 * 6) {
        window.alert("Maximum photo size should be 5mb");
      } else {
        handleChange(event);
        setAvatar(event.target.files[0]);
        const reader = new FileReader();
        reader.onload = function (e) {
          setdimg(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  };

  const fetchAdId = () => {
    axios.get(`${baseUrl}/api/ads/getAdsTitle`).then((res) => {
      console.log('Res>>', res.data.title);
      setSelectedAds(editedDebate?.adId);
      setAdsList(res?.data?.title);
    });
  };

  useEffect(() => {
    fetchAdId();
    // setSelectedAds(editedDebate?.adId);
  }, []);

  const ChooseAdsMultiSelect = ({ adsList, selectedAds, onChange }) => {
    const handleChange = (e, newValue) => {
      console.log('newValue', newValue)
      onChange(newValue);
    };
    console.log('Seleceted Ads', selectedAds, "adsList", adsList);
    return (
      <Autocomplete
        multiple
        options={adsList}
        getOptionLabel={(ad) => ad}
        value={selectedAds}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Choose Ads" placeholder="Select multiple ads" />
        )}
      />
    );
  };

  const { errors, values, touched, handleSubmit, isSubmitting, handleBlur, getFieldProps, handleChange } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Title"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
          <TextField
            fullWidth
            label="Buffer Time in mins"
            {...getFieldProps('bufferTime')}
            error={Boolean(touched.bufferTime && errors.bufferTime)}
            helperText={touched.bufferTime && errors.bufferTime}
          />

          <TextField
            fullWidth
            label="Description"
            multiline
            rows={4}
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />

          <Input
            fullWidth
            type="file"
            label="Image Url"
            name="imageUrl"
            onChange={onChangeImages}
            onBlur={handleBlur}
            // value={values.imageUrl}
            error={Boolean(touched.imageUrl && errors.imageUrl)}
            helperText={touched.imageUrl && errors.imageUrl}
          />
          {dimg ? (
            <img width={60} alt={'sss'} src={dimg} />
          ) : (
            <img width={60} alt={values.imageUrl} src={`${baseUrl}/${values.imageUrl}`} />
          )}

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Topic</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.topic}
                label="Topic"
                error={Boolean(touched.topic && errors.topic)}
                helperText={touched.topic && errors.topic}
                onChange={(e) => {
                  formik.setFieldValue('topic', e.target.value);
                }}
              >
                {topics?.map((t, i) => (
                  <MenuItem key={i} value={t._id}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120 }}>
            {adsList.length > 0 ? <ChooseAdsMultiSelect adsList={adsList} selectedAds={selectedAds} onChange={handleAdsChange} /> : "loading.."}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <InputLabel id="demo-simple-select-label">Start Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                inputFormat="DD-MM-YYYY HH:mm"
                renderInput={(props) => <TextField {...props} />}
                onChange={(v) => {
                  formik.setFieldValue('startDate', v);
                }}
                value={formik.values.startDate}
              />
            </LocalizationProvider>
          </Box>
          {/* <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <InputLabel id="demo-simple-select-label">End Date</InputLabel>
                        <DateTimePicker onChange={(v) => { formik.setFieldValue('endDate', v) }} value={formik.values.endDate} />
                    </Box> */}

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Duration</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.duration}
                label="Duration"
                onChange={(e) => {
                  console.log('---duration---', e.target.value);
                  formik.setFieldValue('duration', e.target.value);
                }}
              >
                {[15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180]?.map((t, i) => (
                  <MenuItem key={i} value={t}>
                    {t} mins
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          // disabled={isLoading || risLoading}
          // loading={isSubmitting || isLoading || risLoading}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default DebateForm;
