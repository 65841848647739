import { LoadingButton } from '@mui/lab';
import { Card, Container, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import Logo from '../components/Logo';
import Page from '../components/Page';
import useResponsive from '../hooks/useResponsive';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

function ForgetPassword() {
  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="ForgetPassword">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h3" gutterBottom>
              Forgot your password?
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5, textAlign: 'center' }}>
              Please enter the email address associated with your account and We will email you a link to reset your
              password.
            </Typography>
            <Stack width={'100%'} spacing={3}>
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                //   {...getFieldProps('email')}
                //   error={Boolean((touched.email && errors.email) || isError)}
                //   helperText={isError || (touched.email && errors.email)}
              />

              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={false}>
                Send Request
              </LoadingButton>
              <LoadingButton fullWidth size="large" type="submit" variant="text" loading={false}>
                Back
              </LoadingButton>
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

export default ForgetPassword;
