import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function AdsMoreMenu({ setSelectedUser, user, handleOpen }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            {/* <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
            </IconButton> */}

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {/* {user?.role?.name !== 'admin' ? (
                    <MenuItem
                        sx={{ color: 'text.secondary' }}
                        onClick={() => {
                            handleDeleteuser(user);
                            setIsOpen(false);
                        }}
                    >
                        <ListItemIcon>
                            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                ) : null} */}

                <MenuItem
                    sx={{ color: 'text.secondary' }}
                    onClick={() => {
                        handleOpen();
                        setSelectedUser(user);
                    }}
                >
                    <ListItemIcon>
                        <Iconify icon="eva:edit-fill" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </Menu>
        </>
    );
}
