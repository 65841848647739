import { IconButton, Menu, MenuItem, Popover } from '@mui/material';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Iconify from '../Iconify';
import { baseUrl } from '../../constant/config';

const DropdownMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);

  // Simulate fetching options from API
  const fetchAllPackages = () => {
    axios
      .get(`${baseUrl}/api/adPackage/getPackages`)
      .then((res) => {
        // console.log('Packages', res.data.adPackages);
        setOptions(res.data.adPackages);
      })
      .catch((error) => {
        console.log('ERROR', error);
      });
  };

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const scrollableMenu = (
    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
      {options.map((option, index) => (
        <MenuItem key={index} onClick={handleMenuClose}>
          {option?.duration}
        </MenuItem>
      ))}
    </div>
  );

  // Fetch the options from the API when the component mounts
  useEffect(() => {
    fetchAllPackages();
  }, []);

  return (
    <div>
      <IconButton onClick={handleIconClick}>
        <Iconify icon="ic:round-filter-list" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {options.length > 4 ? (
          scrollableMenu
        ) : (
          <Menu>
            {options.map((option) => (
              <MenuItem key={option?._id} onClick={handleMenuClose}>
                {option?.duration}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Popover>
    </div>
  );
};

export default DropdownMenu;
