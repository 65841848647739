import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useFormik, Form, FormikProvider } from 'formik';

function AdsPackageForm({ mutate, editedFeature, editFeatureMutate }) {
  const queryClient = useQueryClient();
  console.log('LOGSSSSS>>>>', mutate, editFeatureMutate, editedFeature);
  const RegisterSchema = Yup.object().shape({
    price: Yup.string().required('Price is required'),
    duration: Yup.string().required('Durations is required'),
    status: Yup.string().required('Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      price: '',
      status: '',
      duration: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      if (editedFeature) {
        editFeatureMutate({ featureId: editedFeature._id, ...values });
      } else {
        mutate(values);
      }
    },
  });

  useEffect(() => {
    if (editedFeature) {
      formik.setFieldValue('price', editedFeature.price || '');
      formik.setFieldValue('status', editedFeature.status || 'active');
      formik.setFieldValue('duration', editedFeature.duration || '');
    }
  }, [editedFeature]);

  const handleChange = (event) => {
    // formik.setFieldValue('duration', event.target.value);
    formik.setFieldValue('status', event.target.value);
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Price"
            {...getFieldProps('price')}
            error={Boolean(touched.price && errors.price)}
            helperText={touched.price && errors.price}
          />

          <TextField
            fullWidth
            label="Duration"
            rows={4}
            {...getFieldProps('duration')}
            error={Boolean(touched.duration && errors.duration)}
            helperText={touched.duration && errors.duration}
          />
          <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.values.status}
                  label="Status"
                  onChange={handleChange}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                >
                  
                    <MenuItem value='active'>
                      Active
                    </MenuItem>
                    <MenuItem value='inactive'>
                      Inactive
                    </MenuItem>
                </Select>
              </FormControl>
            </Box>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            // disabled={isLoading || risLoading}
            // loading={isSubmitting || isLoading || risLoading}
          >
            {!editedFeature ? 'ADD' : 'UPDATE'}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default AdsPackageForm;
