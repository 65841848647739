import PropTypes from 'prop-types';
// import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
// import { faker } from '@faker-js/faker';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
// @mui
import {
    Box,
    List,
    Badge,
    Button,
    Avatar,
    Tooltip,
    Divider,
    Typography,
    IconButton,
    ListItemText,
    ListSubheader,
    ListItemAvatar,
    ListItemButton,
} from '@mui/material';
// import { AuthState } from '../../context/auth';
// utils
import { fToNow } from '../../utils/formatTime';
// components
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { subFolder, baseUrl } from '../../constant/config';
// import NotificationSound from '../../notification-sound.mp3';

// const audioObj = new Audio(NotificationSound);

export default function NotificationsPopover() {
    // const [audio] = useState(audioObj);
    const [playing, setPlaying] = useState(false);
    const queryClient = useQueryClient();
    // const { isAuth } = AuthState();
    const anchorRef = useRef(null);

    const { data: notifications } = useQuery(
        ['notifications'],
        async () => {
            const url = `${baseUrl}/api/admin/getDebateRequestNotAccepted`;
            const { data } = await axios.get(url);
            return data.debatereques;
        },
        {
            // enabled: !!isAuth,
            refetchInterval: 5000,
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
        }
    );

    const { mutate: handleMarkAllAsRead } = useMutation(
        async () => {
            const { data } = await axios.put(`${baseUrl}/api/admin/readAllDebateNotificationRequest`);
            return data;
        },
        {
            onSuccess: (d) => {
                
                queryClient.invalidateQueries('notifications');
                queryClient.invalidateQueries('debate_requests');
                window.location.reload()
            },
        }
    );

    const totalUnRead = notifications?.filter((item) => item.read === false).length;

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    // useEffect(() => {
    //     console.log("playing", playing);
    //     if (playing) {
    //         audio.play();
    //         setPlaying(false);
    //     }
    // }, [audio, playing]);

    // useEffect(() => {
    //     console.log("totalUnRead", totalUnRead);
    //     if (totalUnRead) {
    //         setPlaying(true);
    //     }
    // }, [totalUnRead]);

    return (
        <>
            <IconButton
                ref={anchorRef}
                color={open ? 'primary' : 'default'}
                onClick={handleOpen}
                sx={{ width: 40, height: 40 }}
            >
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify icon="eva:bell-fill" width={20} height={20} />
                </Badge>
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">Notifications</Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            You have {totalUnRead} unread messages
                        </Typography>
                    </Box>

                    {totalUnRead > 0 && (
                        <Tooltip title=" Mark all as read">
                            <IconButton color="primary" onClick={handleMarkAllAsRead}>
                                <Iconify icon="eva:done-all-fill" width={20} height={20} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
                    <List
                        disablePadding
                        subheader={
                            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                                Join Request
                            </ListSubheader>
                        }
                    >
                        {notifications?.map((notification, i) => (
                            <NotificationItem key={i} notification={notification} />
                        ))}
                    </List>
                </Scrollbar>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Box sx={{ p: 1 }}>
                    <RouterLink onClick={handleClose} to={'/dashboard/notification'}>
                        <Button fullWidth disableRipple>
                            View All
                        </Button>
                    </RouterLink>
                </Box>
            </MenuPopover>
        </>
    );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        createdAt: PropTypes.any,
        id: PropTypes.string,
        isUnRead: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        avatar: PropTypes.any,
    }),
};

function NotificationItem({ notification }) {
    const { avatar, title } = renderContent(notification);
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
        async (v) => {
            const { data } = await axios.put(`${baseUrl}/api/admin/updateDebateRequest`, { ...v });
            return data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['debate_requests']);
            },
        }
    );

    return (
        <ListItemButton
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
                // bgcolor: 'action.selected',
            }}
            onClick={() =>
                // eslint-disable-next-line react/prop-types
                mutate({ debateId: notification.debateId._id, userId: notification.userId._id, accepted: true })
            }
        >
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.disabled',
                        }}
                    >
                        <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                        {fToNow(notification.createdAt)}
                    </Typography>
                }
            />
        </ListItemButton>
    );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
    const title = (
        <Typography variant="subtitle2" noWrap>
            {notification?.userId?.email}
            <br />
            <Typography component="span" noWrap={false} variant="body2" sx={{ color: 'text.secondary' }}>
                {noCase(notification?.debateId?.title)}
            </Typography>
        </Typography>
    );
    return {
        avatar: <img alt={notification?.debateId?.title} src={`${subFolder}/static/icons/ic_notification_chat.svg`} />,
        title,
    };
}
