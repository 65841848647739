// /* eslint-disable import/no-unresolved */
import {
  Box,
  Button,
  Card,
  Container,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Switch
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import TopicForm from '../components/TopicForm';
import { baseUrl } from '../constant/config';
import { AuthState } from '../context/auth';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Topic() {
  const [editedTopic, setEditedTopic] = useState(null);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { topics } = AuthState();

  // const { data:topics, isLoading, isError } = useQuery(
  //     ['allTopics'],
  //     async () => {
  //       const url = `${baseUrl}/api/admin/getTopics`;
  //       const { data } = await axios.get(url);
  //       return data.data;
  //     },
  //     {
  //       // enabled: !!isAuth,
  //       refetchOnWindowFocus:false,refetchOnReconnect:false
  //     }
  //   );

  const { mutate } = useMutation(
    async (variables) => {
      const { data } = await axios.post(
        `${baseUrl}/api/admin/addTopic`,

        variables
      );
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allTopics');
        setOpen(false);
      },
    }
  );

  const {
    mutate: editTopicMutate,
    isLoading: editRoleLoading,
  } = useMutation(
    async (variables) => {
      console.log(variables);
      const { data } = await axios.put(`${baseUrl}/api/admin/editTopic`, variables);
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allTopics');
        setEditedTopic(null);
        setOpen(false);
      },
    }
  );

  const {
    mutate: deleteTopic,
    isLoading: deleteRoleLoading,
  } = useMutation(
    async (topicId) => {
      const { data } = await axios.post(`${baseUrl}/api/admin/deleteTopic`, { topicId });
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allTopics');
      },
    }
  );

  const handleOpen = () => {
    setEditedTopic(null);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handelEditRole = useCallback((plan) => {
    setEditedTopic(plan);
    setOpen(true);
  }, []);

  const handelDelete = (row) => {
    const texts = `Delete ${row.name} topic? Press Ok`;
    if (window.confirm(texts) === true) {
      deleteTopic(row._id);
    }
  };

  React.useEffect(() => {
    queryClient.invalidateQueries('allTopics');
  }, []);

  return (
    <Page title="Topics">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Topics
          </Typography>
          <Box sx={{ display: 'flex', gridGap: '10px' }}>
            <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              New topic
            </Button>
          </Box>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Visibility</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    {/* <TableCell align="right">Delete</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topics &&
                    topics.map((row, i) => (
                      <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {i+1}
                        </TableCell>
                        <TableCell component="th" scope="row" className='one_line_text' style={{ minWidth: '120px', maxWidth: '120px' }}>
                          {row.name}
                        </TableCell>
                        <TableCell component="th" scope="row" className='one_line_text' style={{ minWidth: '120px', maxWidth: '180px' }}>
                          {row.title}
                        </TableCell>
                        <TableCell component="th" scope="row" className='one_line_text' style={{ minWidth: '120px', maxWidth: '180px' }}>
                          {row.description}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.positionOnMenu}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          <Switch
                            defaultChecked={row.showOnMenu}
                            value={row.showOnMenu}
                            onChange={(e) => { editTopicMutate({ topicId: row._id, showOnMenu: !row.showOnMenu }) }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <EditIcon sx={{ ':hover': { cursor: 'pointer' } }} onClick={() => handelEditRole(row)} />
                        </TableCell>
                        {/* <TableCell align="right">
                          <DeleteIcon
                            sx={{ ':hover': { cursor: 'pointer' } }}
                            onClick={() => handelDelete(row)}
                          />
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
        <Dialog onClose={handleClose} open={open}>
          <Box sx={{ m: 0, p: 4, minWidth: 320 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={'center'} mb="10px">
              {!editedTopic ? `Add new` : 'Edit'} topic
            </Typography>
            <Box sx={{ width: '100%' }}>
              <TopicForm mutate={mutate} editedTopic={editedTopic} editTopicMutate={editTopicMutate} />
            </Box>
          </Box>
        </Dialog>
      </Container>
    </Page>
  );
}

export default Topic;
