/* eslint-disable import/no-unresolved */
import { Button, TableCell, TableRow } from '@mui/material';
import React, { useCallback } from 'react';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { baseUrl } from 'src/constant/config';

function DebateRow({ inc, row, count, handelEditDebate, handelViewReqs, deleteTopic }) {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    async (variables) => {
      const { data } = await axios.put(
        `${baseUrl}/api/admin/startDebate`,

        variables
      );
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.refetchQueries('allDebtes');
      },
    }
  );
  const handleDeleteClick = () => {
    axios
      .post(`${baseUrl}/api/admin/deleteDebate/${row._id}`, {
        deleteStatus: 'active',
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log('Error', error);
      });
  };

  const handelToggleDebate = useCallback(() => {
    mutate({
      debateId: row._id,
      roomId: row.roomId,
      isStarted: !row.isStarted,
    });
  }, [row, mutate]);

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row" className='one_line_text'>
        {count - inc}
      </TableCell>
      <TableCell component="th" scope="row" style={{ minWidth: '180px', maxWidth: '180px' }} className='one_line_text'>
        {row.title}
      </TableCell>
      <TableCell component="th" scope="row" style={{ minWidth: '180px', maxWidth: '380px' }} className='one_line_text'>
        {row.description}
      </TableCell>
      <TableCell component="th" scope="row" style={{ minWidth: '180px'}}>
        {new Date(row.startDate).toLocaleDateString()} {new Date(row.startDate).toLocaleTimeString()}
      </TableCell>
      <TableCell component="th" scope="row" style={{ minWidth: '100px'}}>
        {row.duration} mins
      </TableCell>
      <TableCell>
        <EditIcon sx={{ ':hover': { cursor: 'pointer' } }} onClick={() => handelEditDebate(row)} />
      </TableCell>
      {/* <TableCell >
        <PeopleAltIcon sx={{ ':hover': { cursor: 'pointer' } }} onClick={() => handelViewReqs(row)} />
      </TableCell> */}
      {/* <TableCell>
        <DeleteIcon sx={{ ':hover': { cursor: 'pointer' } }} onClick={() => deleteTopic(row._id)} />
      </TableCell> */}
      <TableCell id="bm-4" align="left" style={{ minWidth: '170px' }}>
        <Button
          variant="contained"
          size="small"
          color={row.isStarted ? 'error' : 'success'}
          // disabled={row.isStarted}
          onClick={handelToggleDebate}
        >
          {row.isStarted ? `Stop` : `Start`}
        </Button>

        <Button
          variant="contained"
          size="small"
          color={'error'}
          onClick={handleDeleteClick}
          // disabled={row.isStarted}
          // onClick={handelToggleDebate}
          style={{ marginLeft: '10px' }}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default DebateRow;
