/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import {
  Button,
  Card,
  Chip,
  Container,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useCallback, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import { AuthState } from '../context/auth';
import axios from 'axios';
import { baseUrl } from '../constant/config';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Box } from '@mui/system';
import Iconify from 'src/components/Iconify';
import PlanForm from 'src/components/PlanForm';
import FeatureForm from 'src/components/FeatureForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Feature() {
  const [editedFeature, setEditedFeature] = useState(null);
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const { features } = AuthState();

  const { mutate } = useMutation(
    async (variables) => {
      const { data } = await axios.post(
        `${baseUrl}/api/planfeature/create`,

        variables
      );
      return data;
    },
    {
      onSuccess: (d) => {
        setOpen(false);
        queryClient.refetchQueries('allFeatures');
        if (d?.message) {
          alert(d?.message);
        }
      },
      onError: (d) => {
        console.log(d.response.data.message);
        if (d?.response?.data?.message) {
          alert(d?.response?.data?.message);
        }
      },
    }
  );

  const {
    mutate: editFeatureMutate,
    // data: deleteData,
    isLoading: editRoleLoading,
  } = useMutation(
    async (variables) => {
      const { data } = await axios.put(`${baseUrl}/api/planfeature/update`, variables);
      return data;
    },
    {
      onSuccess: (d) => {
        setOpen(false);
        queryClient.invalidateQueries('allFeatures');
        if (d?.message) {
          alert(d?.message);
        }
      },
      onError: (d) => {
        console.log(d.response.data.message);
        if (d?.response?.data?.message) {
          alert(d?.response?.data?.message);
        }
      },
    }
  );
  const {
    mutate: deleteFeature,
    // data: deleteData,
    isLoading: deleteRoleLoading,
  } = useMutation(
    async (featureId) => {
      const { data } = await axios.post(`${baseUrl}/api/planfeature/delete`, { featureId });
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allFeatures');
      },
      onError: (d) => {
        console.log(d.response.data.message);
        if (d?.response?.data?.message) {
          alert(d?.response?.data?.message);
        }
      },
    }
  );

  const handleOpen = () => {
    setEditedFeature(null);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handelEditRole = useCallback((plan) => {
    setEditedFeature(plan);
    setOpen(true);
  }, []);

  const handelDelete = (row) => {
    const texts = `Delete ${row.name} feature? Press Ok`;
    if (window.confirm(texts) === true) {
      deleteFeature(row._id);
    }
  };



  useEffect(() => {
    queryClient.refetchQueries('allFeatures');
  }, []);

  return (
    <Page title="Plans">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Features
          </Typography>
          <Box sx={{ display: 'flex', gridGap: '10px' }}>
            <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              New feature
            </Button>
          </Box>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    <TableCell align="right">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {features &&
                    features.map((row, i) => (
                      <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {i + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.description}
                        </TableCell>
                        <TableCell align="right">
                          <EditIcon sx={{ ':hover': { cursor: 'pointer' } }} onClick={() => handelEditRole(row)} />
                        </TableCell>
                        <TableCell align="right">
                          <DeleteIcon sx={{ ':hover': { cursor: 'pointer' } }} onClick={() => handelDelete(row)} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
        <Dialog onClose={handleClose} open={open}>
          <Box sx={{ m: 0, p: 4, minWidth: 320 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={'center'} mb="10px">
              {!editedFeature ? `Add New` : 'Edit'} Feature
            </Typography>
            <Box sx={{ width: '100%' }}>
              <FeatureForm mutate={mutate} editedFeature={editedFeature} editFeatureMutate={editFeatureMutate} />
            </Box>
          </Box>
        </Dialog>
      </Container>
    </Page>
  );
}

export default Feature;
