import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { baseUrl } from '../constant/config';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isAuth, setisAuth] = useState(null);
  const [authUser, setAuthUser] = useState(null);
  const { data: topics } = useQuery(
    ['allTopics'],
    async () => {
      const url = `${baseUrl}/api/admin/getTopics`;
      const { data } = await axios.get(url);
      return data.data;
    },
    {
      // enabled: !!isAuth,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  const { data: roles } = useQuery(
    'allRoles',
    async () => {
      const { data } = await axios.get(`${baseUrl}/api/admin/getRoles`);
      return data.roles;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: (d) => {
        // console.log('data', d);
      },
    }
  );
  const {
    data: features,
    isLoading,
    isError,
  } = useQuery(
    ['allFeatures'],
    async () => {
      const url = `${baseUrl}/api/planfeature/getAll`;
      const { data } = await axios.get(url);
      return data.data;
    },
    {
      // enabled: !!isAuth,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  useQuery(
    'loadKey',
    () => {
      const token = localStorage.getItem('admin_token');
      return token;
    },
    {
      onSuccess: (d) => {
        // console.log('data', d);
        if (d) {
          setisAuth(d);
          // setisAuth(true);
          axios.defaults.headers.common.Authorization = `${d}`;
        } else setisAuth(null);
      },
    }
  );

  return (
    <AuthContext.Provider
      value={{
        topics,
        features,
        roles,
        isAuth,
        setisAuth,
        authUser,
        setAuthUser,
      }}
    >
      <>{children}</>
    </AuthContext.Provider>
  );
};

export const AuthState = () => useContext(AuthContext);
