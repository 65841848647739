import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';
import { AuthState } from './context/auth';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import ProtectedRoute from './layouts/ProtectedRoute';
import ForgetPassword from './pages/ForgetPassword';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Role from './pages/Role';
import Plan from './pages/Plan';
import User from './pages/User';
import Feature from './pages/Feature';
import Topic from './pages/Topic';
import Debate from './pages/Debate';
import Notification from './pages/Notification';
import Banner from './pages/Banner';
import About from './pages/About';
import { TermsConditions } from './pages/Terms&Conditions';
import Ads from './pages/Ads';
import AdsPackages from './pages/AdsPackages';
import AdsDetails from './components/Ads/AdsDetails';

// ----------------------------------------------------------------------

export default function Router() {
  const { isAuth } = AuthState();

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'user',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <User />
            </ProtectedRoute>
          ),
        },
        {
          path: 'role',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <Role />
            </ProtectedRoute>
          ),
        },
        {
          path: 'plan',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <Plan />
            </ProtectedRoute>
          ),
        },
        {
          path: 'feature',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <Feature />
            </ProtectedRoute>
          ),
        },
        {
          path: 'topic',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <Topic />
            </ProtectedRoute>
          ),
        },
        {
          path: 'debate',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <Debate />
            </ProtectedRoute>
          ),
        },
        {
          path: 'notification',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <Notification />
            </ProtectedRoute>
          ),
        },
        {
          path: 'banner',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <Banner />
            </ProtectedRoute>
          ),
        },
        {
          path: 'about',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <About />
            </ProtectedRoute>
          ),
        },
        {
          path: 'terms-and-conditions',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <TermsConditions />
            </ProtectedRoute>
          ),
        },
        {
          path: 'ads',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <Ads />
            </ProtectedRoute>
          ),
        },
        {
          path: 'ads-packages',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <AdsPackages />
            </ProtectedRoute>
          ),
        },
        {
          path: 'adsDetails/:id',
          element: (
            <ProtectedRoute isAuth={isAuth}>
              <AdsDetails />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/user" /> },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'forgetPassword',
          element: <ForgetPassword />,
        },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
// { path: 'app', element: <DashboardApp /> },
// { path: 'products', element: <Products /> },
// { path: 'blog', element: <Blog /> },
// { path: 'register', element: <Register /> },

//  return (
//    <Routes>
//      <Route
//        path="/dashboard/user"
//        element={
//          <ProtectedRoute isAuth={isAuth}>
//            <DashboardLayout>
//              <User />
//            </DashboardLayout>
//          </ProtectedRoute>
//        }
//      />
//      <Route
//        path="/login"
//        element={
//          <LogoOnlyLayout>
//            <Login />
//          </LogoOnlyLayout>
//        }
//      />
//      <Route path="/404" element={<NotFound />} />
//      {/* <Route path="/*" element={<Navigate to="/404" />} /> */}
//    </Routes>
//  );
