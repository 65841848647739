// material
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { sentenceCase } from 'change-case';
import { filter } from 'lodash';
import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { baseUrl } from '../constant/config';
import Iconify from '../components/Iconify';
import Label from '../components/Label';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { AuthState } from '../context/auth';
import UserRow from '../components/UserRow';
import { RegisterForm } from '../sections/auth/register';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'company', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'username', label: 'UserName', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
  // { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterRoleId, setFilterRoleId] = useState(null);
  const [srchtxt, setSrchtxt] = useState(null);

  const [orderBy, setOrderBy] = useState('name');

  // const [srchtxt, setSrchtxt] = useState('');
  const [role, setRole] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { isAuth } = AuthState();

  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [ropen, setrOpen] = useState(false);

  const handlerOpen = () => {
    setrOpen(true);
  };
  const handlerClose = () => setrOpen(false);

  const handleOpen = () => {
    setSelectedUser(null);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handelChangeRole = useCallback((e) => {
    setRole(e.target.value);
  }, []);

  const { data, isLoading, isError } = useQuery(
    ['allUsers', filterRoleId, srchtxt],
    async () => {
      let url = `${baseUrl}/api/admin/getAllUser`;
      if (filterRoleId && srchtxt) {
        url = `${baseUrl}/api/admin/getAllUser?roleId=${filterRoleId}&srchtxt=${srchtxt}`;
      } else if (filterRoleId) {
        url = `${baseUrl}/api/admin/getAllUser?roleId=${filterRoleId}`;
      } else if (srchtxt) {
        url = `${baseUrl}/api/admin/getAllUser?srchtxt=${srchtxt}`;
      }
      const { data } = await axios.get(url);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: !!isAuth,
    }
  );

  const { mutate, isLoading: mLoading } = useMutation(
    async (variables) => {
      const { data } = await axios.delete(`${baseUrl}/api/admin/deleteUser`, { userId: selectedUser._id });
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allUsers');
      },
    }
  );

  const {
    mutate: deleteMutate,
    data: deleteData,
    isLoading: deleteLoading,
  } = useMutation(
    async (variables) => {
      const { data } = await axios.delete(`${baseUrl}/api/admin/deleteUser`, { data: { userId: variables.userId } });
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allUsers');
      },
    }
  );
  const {
    mutate: addRoleMutate,
    // data: deleteData,
    isLoading: addRoleLoading,
  } = useMutation(
    async (variables) => {
      const { data } = await axios.post(`${baseUrl}/api/admin/addRole`, variables);
      return data;
    },
    {
      onSuccess: (d) => {
        handlerClose();
        queryClient.invalidateQueries('allRoles');
      },
    }
  );

  // console.log('deleteData', deleteData);

  const handleRoleSubmit = useCallback(() => {
    addRoleMutate();
  }, [addRoleMutate]);
  const handleDeleteuser = useCallback(
    (user) => {
      console.log('user', user);
      deleteMutate({ userId: user._id });
    },
    [deleteMutate]
  );

  const handelStatusUpdate = useCallback(
    (userId, status) => {
      mutate({
        userId,
        status,
      });
    },
    [mutate]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setSrchtxt(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), srchtxt);

  const isUserNotFound = filteredUsers.length === 0;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const RegisterSchema = Yup.object().shape({
    role: Yup.string().min(2, 'Too Short!').max(20, 'Too Long!').required('role is required'),
  });

  const formik = useFormik({
    initialValues: {
      role: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      addRoleMutate(values);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Users">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Box sx={{ display: 'flex', gridGap: '10px' }}>
            <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              New User
            </Button>
            <Button variant="contained" onClick={handlerOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              New Role
            </Button>
          </Box>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            srchtxt={srchtxt}
            onFilterName={handleFilterByName}
            setFilterRoleId={setFilterRoleId}
            filterRoleId={filterRoleId}
          />
          {isLoading ? (
            <Box justifyContent={'center'} alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data?.users?.length || 0}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {data &&
                      data?.users
                        // ?.filter((u) => u.role.name !== 'admin')
                        .map((u, idx) => (
                          <UserRow
                            key={idx}
                            u={u}
                            selected={selected}
                            handleClick={handleClick}
                            handelStatusUpdate={handelStatusUpdate}
                            mLoading={mLoading}
                            setSelectedUser={setSelectedUser}
                            handleOpen={handleOpen}
                            handleDeleteuser={handleDeleteuser}
                          />
                        ))}

                    {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>

                  {/* {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={srchtxt} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )} */}
                </Table>
              </TableContainer>
            </Scrollbar>

            /* <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data?.users?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      /> */
          )}
        </Card>

        <Dialog onClose={handleClose} open={open}>
          <Box sx={{ m: 0, p: 4, minWidth: 320 }}>
            <Typography id="modal-modal-title" variant="h5" component="h5" textAlign={'center'} mb="20px">
              {selectedUser ? "Edit User" : "Add New User"}
            </Typography>
            <Box sx={{ width: '100%' }}>
              <RegisterForm selectedUser={selectedUser} />
            </Box>
          </Box>
        </Dialog>

        <Dialog onClose={handlerClose} open={ropen}>
          <Box sx={{ m: 0, p: 4, minWidth: 320 }}>
            <Typography id="modal-modal-title" variant="h5" component="h5" textAlign={'center'} mb="20px">
              Add New Role
            </Typography>
            <Box sx={{ width: '100%' }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <TextField
                      fullWidth
                      label="Role"
                      {...getFieldProps('role')}
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                    />
                    <Button type="submit" variant="contained" disabled={addRoleLoading}>
                      Submit
                    </Button>
                  </Stack>
                </Form>
              </FormikProvider>
            </Box>
          </Box>
        </Dialog>
      </Container>
    </Page>
  );
}

/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, role, status, company, avatarUrl, isVerified } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{company}</TableCell>
                          <TableCell align="left">{role}</TableCell>
                          <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={(status === 'banned' && 'error') || 'success'}>
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <UserMoreMenu />
                          </TableCell>
                        </TableRow>
                      );
                    })} */
