/* eslint-disable import/order */
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { baseUrl } from '../../../constant/config';
import { AuthState } from '../../../context/auth';

// ----------------------------------------------------------------------

export default function RegisterForm({ isRegister, selectedUser }) {
  // const navigate = useNavigate();
  const { roles } = AuthState();
  const [showPassword, setShowPassword] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: addUser, isLoading } = useMutation(async (variables) => {
    const { data } = await axios.post(`${baseUrl}/api/admin/addUser`, variables);
    return data;
  });
  const { mutate: updateUser, isLoading: risLoading } = useMutation(async (variables) => {
    const { data } = await axios.put(`${baseUrl}/api/admin/updateUser`, variables);
    return data;
  });

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    username: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('User name required').matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    role: Yup.string().required('Role is required'),
    phone: Yup.number().min(10, 'Too Short!').required('Phone is required'),
    password: Yup.string().min(8, 'Too Short!').required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: selectedUser?.firstName || '',
      lastName: selectedUser?.lastName || '',
      username: selectedUser?.username || '',
      email: selectedUser?.email || '',
      phone: selectedUser?.phone || '',
      role: selectedUser?.role.name || '',
      password: selectedUser?.password || '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, action) => {
      if (selectedUser) {
        updateUser(
          { ...values, role: roles.find((r) => r.name === values.role)?._id, userId: selectedUser?._id },
          {
            onSuccess: () => {
              queryClient.refetchQueries('allUsers');
              alert('User successfully updated')
            },
            onError: (error, variables, context) => {
              if (error?.response?.data?.message) {
                alert(error.response.data.message)
              }
            },
          }
        );
      } else {
        addUser(
          { ...values, role: roles.find((r) => r.name === values.role)?._id },
          {
            onSuccess: () => {
              queryClient.refetchQueries('allUsers');
              action.resetForm();
              alert('User successfully registered')
            },
            onError: (error, variables, context) => {
              if (error?.response?.data?.message) {
                alert(error.response.data.message)
              }
            },
          }
        );
      }

      // navigate('/dashboard/user', { replace: true });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleChange = (event) => {
    formik.setFieldValue('role', event.target.value);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First Name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <TextField
              fullWidth
              label="Last Name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            label="User name"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />
          <TextField
            fullWidth
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            label="Phone"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />

          {!selectedUser && (
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          )}

          {roles && roles.length > 0 && (
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.values.role}
                  label="Age"
                  onChange={handleChange}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                >
                  {roles.map((r, i) => (
                    <MenuItem key={i} value={r.name}>
                      {r.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={isLoading || risLoading}
            loading={isSubmitting || isLoading || risLoading}
          >
            {selectedUser ? 'Update' : 'Submit'}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
