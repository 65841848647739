/* eslint-disable import/order */
// routes
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
// components
import ScrollToTop from './components/ScrollToTop';
import { AuthContextProvider } from './context/auth';
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { subFolder } from './constant/config';

// ----------------------------------------------------------------------
const queryClient = new QueryClient();

export default function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <BrowserRouter basename={subFolder}>
              <ScrollToTop />
              <Router />
            </BrowserRouter>
          </AuthContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}
