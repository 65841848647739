import { Avatar, Checkbox, CircularProgress, Stack, Switch, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { UserMoreMenu } from '../sections/@dashboard/user';

function UserRow({ u, selected, handleClick, handelStatusUpdate, mLoading, handleDeleteuser, setSelectedUser, handleOpen }) {
  const { _id, firstName,lastName, email, role, username, phone } = u;
  const isItemSelected = selected.indexOf(_id) !== -1;

  return (
    <TableRow hover tabIndex={-1} role="checkbox" selected={isItemSelected} aria-checked={isItemSelected}>
      <TableCell padding="checkbox">
        {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} /> */}
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            alt={firstName}
            src={
              'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80'
            }
          />
          <Typography variant="subtitle2" noWrap>
            {firstName} {lastName}
          </Typography>
        </Stack>
      </TableCell>
      {/* <TableCell align="left">{name}</TableCell> */}
      <TableCell align="left">{role?.name}</TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="left">{username}</TableCell>
      <TableCell align="left">{phone}</TableCell>
      {/* <TableCell align="left">{!active ? 'Yes' : 'No'}</TableCell> */}
      {/* <TableCell>
        <Switch
          defaultValue={active}
          defaultChecked={active}
          color="secondary"
          value={active}
          onChange={(e) => {
            console.log(e.target.checked);
            handelStatusUpdate(_id, e.target.checked);
          }}
        />
      </TableCell> */}
      {/* <TableCell align="left">
                              <Label variant="ghost" color={(status === 'banned' && 'error') || 'success'}>
                                {sentenceCase(status)}
                              </Label>
                            </TableCell> */}

      <TableCell align="right">
        <UserMoreMenu user={u} setSelectedUser={setSelectedUser} handleOpen={handleOpen}
          handleDeleteuser={handleDeleteuser} />
      </TableCell>
    </TableRow>
  );
}

export default UserRow;
