import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { baseUrl } from '../constant/config';
import Page from '../components/Page';
import AdsPackageForm from '../components/Ads/AdsPackageForm';
import Iconify from '../components/Iconify';

function AdsPackages() {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data: packages, isSuccess } = useQuery(
    ['packages'],
    async () => {
      const { data } = await axios.get(`${baseUrl}/api/adPackage/getPackages`);
      console.log(data);
      return data.adPackages;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const { mutate } = useMutation(
    async (variables) => {
      const { data } = await axios.post(`${baseUrl}/api/adPackage/createAdPackage`, variables);
      return data;
    },
    {
      onSuccess: (response) => {
        setOpen(false);
        queryClient.refetchQueries('packages');
        window.alert(response.msg);
      },
      onError: (d) => {
        console.log(d);
        window.alert(d?.response?.data?.message || d.message);
      },
    }
  );

  // console.log('packages', packages)

  const TABLE_HEAD = [
    { id: 'duration', label: 'Duration', alignRight: false },
    // { id: 'company', label: 'Email', alignRight: false },
    { id: 'price', label: 'Price', alignRight: false },
    // { id: 'email', label: 'Email', alignRight: false },
    // { id: 'adPackage', label: 'Ads Package', alignRight: false },
    // { id: 'phone', label: 'Phone', alignRight: false },
    // { id: 'noImages', label: 'No of Images', alignRight: false },
    // // { id: 'isVerified', label: 'Verified', alignRight: false },
    // { id: '' },
  ];

  return (
    <Page title="Ads Package">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Ads Package
          </Typography>
          <Box sx={{ display: 'flex', gridGap: '10px' }}>
            <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              New Ads Package
            </Button>
          </Box>
        </Stack>
        <Card>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                    // align={headCell.alignRight ? 'right' : 'left'}
                    // sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                      // hideSortIcon
                      // active={orderBy === headCell.id}
                      // direction={orderBy === headCell.id ? order : 'asc'}
                      // onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {/* {orderBy === headCell.id ? (
                          <Box sx={{ ...visuallyHidden }}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null} */}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isSuccess &&
                  packages?.map((ad, index) => {
                    return (
                      <TableRow hover tabIndex={-1} role="checkbox" key={index}>
                        <TableCell>{ad?.duration}</TableCell>
                        <TableCell>{ad?.price}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Dialog onClose={handleClose} open={open}>
          <Box sx={{ m: 0, p: 4, minWidth: 320 }}>
            <Typography id="modal-modal-title" variant="h5" component="h5" textAlign={'center'} mb="20px">
              {'Add New Package'}
            </Typography>
            <Box sx={{ width: '100%' }}>
              <AdsPackageForm mutate={mutate} />
            </Box>
          </Box>
        </Dialog>
      </Container>
    </Page>
  );
}

export default AdsPackages;
