/* eslint-disable import/order */
import { LoadingButton } from '@mui/lab';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  // IconButton,
  // InputAdornment,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material';
// component
// import Iconify from './Iconify';
// import axios from 'axios';
// import { useMutation, useQueryClient } from 'react-query';
// import { baseUrl } from '../constant/config';
import { AuthState } from '../context/auth';

function PlanForm({ mutate, editedPlan, editPlanMutate }) {
  // const queryClient = useQueryClient();
  const { features } = AuthState();
  const RegisterSchema = Yup.object().shape({
    planName: Yup.string().min(2, 'Too Short!').max(20, 'Too Long!').required('Name is required'),
    duration: Yup.number().required('features is required'),
    // features: Yup.string().min(4, 'Too Short!').required('features is required'),
    price: Yup.number().required('features is required'),
  });

  const formik = useFormik({
    initialValues: {
      planName: '',
      price: 0,
      duration: 0,
      features: [],
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      console.log(values);
      if (editedPlan) {
        editPlanMutate({
          planId: editedPlan._id,
          ...values,
          features: undefined,
          featureIds: features.filter((f) => values.features.some((sf) => sf === f.name)).map((f) => f._id),
        });
      } else {
        mutate({
          ...values,
          features: undefined,
          featureIds: features.filter((f) => values.features.some((sf) => sf === f.name)).map((f) => f._id),
        });
      }
    },
  });

  const handleChange = (event) => {
    formik.setFieldValue('duration', event.target.value);
  };

  const handleChangeFeature = (event) => {
    const {
      target: { value },
    } = event;
    console.log('---fea---', value);
    formik.setFieldValue('features', typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    console.log(editedPlan);
    if (editedPlan) {
      formik.setFieldValue('planName', editedPlan?.planName);
      formik.setFieldValue('price', editedPlan?.price);
      formik.setFieldValue('duration', editedPlan?.duration);
      formik.setFieldValue('features', editedPlan?.featureIds?.length > 0 ? editedPlan?.featureIds?.map((f) => f.name) : []);
    }
  }, []);

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Name"
            {...getFieldProps('planName')}
            error={Boolean(touched.planName && errors.planName)}
            helperText={touched.planName && errors.planName}
          />

          <TextField
            fullWidth
            label="Price ($)"
            {...getFieldProps('price')}
            error={Boolean(touched.price && errors.price)}
            helperText={touched.price && errors.price}
          />

          {/* <TextField
            fullWidth
            label="Duration"
            {...getFieldProps('duration')}
            error={Boolean(touched.duration && errors.duration)}
            helperText={touched.duration && errors.duration}
          /> */}

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label">Features</InputLabel>
              <Select
                label="Feature"
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={formik.values.features}
                onChange={handleChangeFeature}
                input={<OutlinedInput label="Feature" />}
                error={Boolean(touched.features && errors.features)}
                helperText={touched.features && errors.features}
              // MenuProps={MenuProps}
              >
                {features &&
                  features.map((f) => (
                    <MenuItem
                      key={f._id}
                      value={f.name}
                    // style={getStyles(f, personName, theme)}
                    >
                      {f.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          {/* <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Features</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.feature}
                label="Age"
                onChange={handleChange}
              >
                {features.map((r, i) => (
                  <MenuItem key={i} value={r.name}>
                    {r.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Duration</InputLabel>
              <Select
                label="Duration"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.duration}
                onChange={handleChange}
                error={Boolean(touched.duration && errors.duration)}
                helperText={touched.duration && errors.duration}
              >
                {Array(12).fill(1).map((val, i) => (
                  <MenuItem key={i} value={val + i}>
                    {val + i} month{i > 0 ? "s" : null}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {!editedPlan ? 'ADD' : 'UPDATE'}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default PlanForm;
