/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import {
  Button,
  Card,
  Chip,
  Container,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useCallback, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import { AuthState } from '../context/auth';
import axios from 'axios';
import { baseUrl } from '../constant/config';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Box } from '@mui/system';
import Iconify from 'src/components/Iconify';
import PlanForm from 'src/components/PlanForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Plan() {
  const { roles } = AuthState();

  const [editedPlan, setEditedPlan] = useState(null);
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const {
    data: plans,
    isLoading,
    isError,
  } = useQuery(
    ['allPlans'],
    async () => {
      const url = `${baseUrl}/api/subscription/getAll`;
      const { data } = await axios.get(url);
      return data.data;
    },
    {
      // enabled: !!isAuth,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const { mutate } = useMutation(
    async (variables) => {
      const { data } = await axios.post(
        `${baseUrl}/api/subscription/create`,

        variables
      );
      return data;
    },
    {
      onSuccess: (d) => {
        console.log('--plan-data--', d);
        queryClient.refetchQueries('allPlans');
        setOpen(false);
        alert('Plan has beed added');
      },
    }
  );

  const {
    mutate: editPlanMutate,
    // data: deleteData,
    isLoading: editRoleLoading,
  } = useMutation(
    async (variables) => {
      const { data } = await axios.put(`${baseUrl}/api/subscription/update`, variables);
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allPlans');
        setEditedPlan(null);
        setOpen(false);
      },
    }
  );
  const {
    mutate: deletePlan,
    // data: deleteData,
    isLoading: deleteRoleLoading,
  } = useMutation(
    async (subscriptionId) => {
      const { data } = await axios.post(`${baseUrl}/api/subscription/delete`, { subscriptionId });
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allPlans');
      },
    }
  );

  const handleOpen = () => {
    setEditedPlan(null);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handelEditRole = useCallback((plan) => {
    setEditedPlan(plan);
    setOpen(true);
  }, []);

  return (
    <Page title="Plans">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Plans
          </Typography>
          {/* <Box sx={{ display: 'flex', gridGap: '10px' }}>
            <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              New Plan
            </Button>
          </Box> */}
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    {/* <TableCell align="right">Delete</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {plans &&
                    plans.map((row, i) => (
                      <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {i + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.planName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.duration} month(s)
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.price}
                        </TableCell>
                        <TableCell align="right">
                          <EditIcon sx={{ ':hover': { cursor: 'pointer' } }} onClick={() => handelEditRole(row)} />
                        </TableCell>
                        {/* <TableCell align="right">
                          <DeleteIcon sx={{ ':hover': { cursor: 'pointer' } }} onClick={() => deletePlan(row._id)} />
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>

        <Dialog onClose={handleClose} open={open}>
          <Box sx={{ m: 0, p: 4, minWidth: 320 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={'center'} mb="10px">
              {editedPlan ? `Edit` : 'Add new'} plan
            </Typography>
            <Box sx={{ width: '100%' }}>
              <PlanForm mutate={mutate} editedPlan={editedPlan} editPlanMutate={editPlanMutate} />
            </Box>
          </Box>
        </Dialog>
      </Container>
    </Page>
  );
}

export default Plan;
