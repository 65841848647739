/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/order */
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import {
    Container,
    Typography,
    Stack,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// import DateTimePicker from 'react-datetime-picker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Page from '../components/Page';


import { baseUrl } from '../constant/config';

function About() {
    const [editedAbout, setEditedAbout] = useState(null);
    const queryClient = useQueryClient();

    const { data: about, isLoading } = useQuery(
        ['About'],
        async () => {
            const url = `${baseUrl}/api/about`;
            const { data } = await axios.get(url);
            return data.data[0];
        },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );

    const { mutate } = useMutation(
        async (variables) => {
            const { data } = await axios.post(`${baseUrl}/api/about/create`, variables, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return data;
        },
        {
            onSuccess: (response) => {
                window.alert(response.msg);
                queryClient.refetchQueries('About');
            },
            onError: (error) => {
                console.log(error);
                window.alert(error.response?.data?.msg || error.message);
            },
        }
    );

    const {
        mutate: editMutate,
        isLoading: AboutLoading,
    } = useMutation(
        async (variables) => {
            const { data } = await axios.post(`${baseUrl}/api/about/update/${editedAbout._id}`, variables, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return data;
        },
        {
            onSuccess: (d) => {
                queryClient.refetchQueries('About');
            },
            onError: (error) => {
                console.log(error);
                window.alert(error.response.statusText || error.message);
            },
        }
    );

    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        imageUrl: Yup.string().required('Image Url is required'),
        title2: Yup.string().required('Title is required'),
        description2: Yup.string().required('Description is required'),
        imageUrl2: Yup.string().required('Image Url is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            imageUrl: '',
            title2: '',
            description2: '',
            imageUrl2: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                formData.set(key, value);
            });
            formData.set("imageUrl", editedAbout?.imageUrl);
            formData.set("imageUrl2", editedAbout?.imageUrl2);
            if (avatar) {
                formData.set('file', avatar);
            }
            if (avatar2) {
                formData.set('file2', avatar2);
            }
            if (editedAbout) {
                editMutate(formData);
            } else {
                mutate(formData);
            }
        },
    });

    const [avatar, setAvatar] = React.useState(null);
    const [dimg, setdimg] = React.useState(null);
    const onChangeImages = (event) => {
        console.log(event.target.files);
        if (!event.target.files) {
            return 0;
        }
        handleChange(event);
        setAvatar(event.target.files[0]);
        if (event.target.files) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setdimg(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    const [avatar2, setAvatar2] = React.useState(null);
    const [dimg2, setdimg2] = React.useState(null);
    const onChangeImages2 = (event) => {
        console.log(event.target.files);
        if (!event.target.files) {
            return 0;
        }
        handleChange(event);
        setAvatar2(event.target.files[0]);
        if (event.target.files) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setdimg2(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const { errors, values, touched, handleSubmit, handleBlur, getFieldProps, handleChange } = formik;

    React.useEffect(() => {
        console.log(about);
        if (about) {
            setEditedAbout(about);
            formik.setFieldValue('title', about.title || '');
            formik.setFieldValue('description', about.description || '');
            formik.setFieldValue('imageUrl', about.imageUrl || '');
            formik.setFieldValue('title2', about.title2 || '');
            formik.setFieldValue('description2', about.description2 || '');
            formik.setFieldValue('imageUrl2', about.imageUrl2 || '');
        }
    }, [about]);

    return (
        <Page title="About">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        About
                    </Typography>
                </Stack>
            </Container>
            <Container>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            <Typography variant="p" gutterBottom>
                                Section 1
                            </Typography>
                            <TextField
                                fullWidth
                                label="Title"
                                {...getFieldProps('title')}
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />

                            <TextField
                                fullWidth
                                label="Description"
                                multiline
                                rows={4}
                                {...getFieldProps('description')}
                                error={Boolean(touched.description && errors.description)}
                                helperText={touched.description && errors.description}
                            />

                            <Input
                                fullWidth
                                type="file"
                                label="Image"
                                name="imageUrl"
                                onChange={onChangeImages}
                                onBlur={handleBlur}
                                // value={values.imageUrl}
                                error={Boolean(touched.imageUrl && errors.imageUrl)}
                                helperText={touched.imageUrl && errors.imageUrl}
                            />
                            {dimg ? (
                                <img width={60} alt={'sss'} src={dimg} />
                            ) : (
                                <img width={60} alt={editedAbout?.imageUrl} src={`${baseUrl}/${editedAbout?.imageUrl}`} />
                            )}

                            <Typography variant="p" gutterBottom>
                                Section 2
                            </Typography>
                            <TextField
                                fullWidth
                                label="Title"
                                {...getFieldProps('title2')}
                                error={Boolean(touched.title2 && errors.title2)}
                                helperText={touched.title2 && errors.title2}
                            />

                            <TextField
                                fullWidth
                                label="Description"
                                multiline
                                rows={4}
                                {...getFieldProps('description2')}
                                error={Boolean(touched.description2 && errors.description2)}
                                helperText={touched.description2 && errors.description2}
                            />

                            <Input
                                fullWidth
                                type="file"
                                label="Image"
                                name="imageUrl2"
                                onChange={onChangeImages2}
                                onBlur={handleBlur}
                                // value={values.imageUrl}
                                error={Boolean(touched.imageUrl2 && errors.imageUrl2)}
                                helperText={touched.imageUrl2 && errors.imageUrl2}
                            />
                            {dimg2 ? (
                                <img width={60} alt={'sss2'} src={dimg2} />
                            ) : (
                                <img width={60} alt={editedAbout?.imageUrl2} src={`${baseUrl}/${editedAbout?.imageUrl2}`} />
                            )}

                            <LoadingButton
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                // disabled={isLoading}
                                loading={isLoading || AboutLoading}
                            >
                                Save
                            </LoadingButton>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Container>
        </Page>
    );
}

export default About;
