/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/order */
import axios from 'axios';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import { Container, Typography, Stack, TextField, Input } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Page from '../components/Page';

import { baseUrl } from '../constant/config';

function Banner() {
    const [editedBanner, setEditedBanner] = useState(null);
    const queryClient = useQueryClient();

    const { data: banner, isLoading } = useQuery(
        ['banner'],
        async () => {
            const url = `${baseUrl}/api/banner`;
            const { data } = await axios.get(url);
            return data.data[0];
        },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );

    const { mutate } = useMutation(
        async (variables) => {
            const { data } = await axios.post(`${baseUrl}/api/banner/create`, variables, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return data;
        },
        {
            onSuccess: (response) => {
                window.alert(response.message);
                queryClient.refetchQueries('banner');
            },
            onError: (error) => {
                console.log(error);
                window.alert(error.response?.data?.msg || error.message);
            },
        }
    );

    const { mutate: editMutate, isLoading: BannerLoading } = useMutation(
        async (variables) => {
            const { data } = await axios.post(`${baseUrl}/api/banner/update/${editedBanner._id}`, variables, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return data;
        },
        {
            onSuccess: (d) => {
                queryClient.refetchQueries('banner');
            },
            onError: (error) => {
                console.log(error);
                window.alert(error.response.statusText || error.message);
            },
        }
    );

    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        imageUrl: Yup.string().required('Image Url is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            imageUrl: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                formData.set(key, value);
            });
            formData.set('imageUrl', JSON.stringify(editedBanner.imageUrl));
            if (photos) {
                Array.from(photos).forEach((file) => {
                    formData.append('photos', file);
                });
            }
            if (editedBanner) {
                editMutate(formData);
            } else {
                mutate(formData);
            }
        },
    });

    const [photos, setPhotos] = React.useState([]);
    const [dimg, setdimg] = React.useState([]);
    const onChangeImages = (event) => {
        console.log(event.target.files);
        const files = event.target.files || event.dataTransfer.files;
        if (files.length > 3) {
            alert('You are only allowed to upload a maximum of 3 files');
        }
        if (!files.length) {
            return 0;
        }
        handleChange(event);
        setPhotos(files);
        setdimg([]);
    };

    React.useEffect(() => {
        if (photos && photos.length) {
            for (let i = 0; i < photos.length; i += 1) {
                console.log(photos[i]);
                const reader = new FileReader();
                reader.onload = function (e) {
                    setdimg((oldArray) => oldArray.concat(e.target.result));
                };
                reader.readAsDataURL(photos[i]);
            }
        }
    }, [photos]);

    const { errors, values, touched, handleSubmit, handleBlur, getFieldProps, handleChange } = formik;

    React.useEffect(() => {
        if (banner) {
            const data = {
                _id: banner._id,
                title: banner.title,
                imageUrl: JSON.parse(banner.imageUrl),
            };
            formik.setFieldValue('title', data.title || '');
            formik.setFieldValue('imageUrl', 'imageUrl');
            setEditedBanner(data);
            console.log(data);
        }
    }, [banner]);

    const ImgStyle = { display: 'inline-block', marginRight: '5px', maxWidth: '120px', maxHeight: '120px' };

    return (
        <Page title="Banner">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Banner
                    </Typography>
                </Stack>
            </Container>
            <Container>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                label="Title"
                                {...getFieldProps('title')}
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />

                            <TextField
                                // hiddenLabel
                                type="file"
                                // label="Image Url"
                                name="imageUrl"
                                variant="outlined"
                                onChange={onChangeImages}
                                onBlur={handleBlur}
                                style={{ position: 'relative' }}
                                inputProps={{
                                    multiple: true,
                                    accept: 'image/*',
                                }}
                                error={Boolean(touched.imageUrl && errors.imageUrl)}
                                helperText={touched.imageUrl && errors.imageUrl}
                            />
                            <div>
                                {dimg.length > 0 && dimg.map((row) => <img style={ImgStyle} alt={'sss'} src={row} />)}
                                {dimg.length === 0 &&
                                    editedBanner?.imageUrl?.length > 0 &&
                                    editedBanner?.imageUrl?.map((row) => <img style={ImgStyle} alt={'sss'} src={`${baseUrl}/${row}`} />)}
                            </div>
                            <LoadingButton size="large" type="submit" variant="contained" loading={isLoading || BannerLoading}>
                                Save
                            </LoadingButton>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Container>
        </Page>
    );
}

export default Banner;
