/* eslint-disable import/order */
import {
  Button,
  Card,
  Chip,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import Iconify from '../components/Iconify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import { AuthState } from '../context/auth';
import axios from 'axios';
import { baseUrl } from '../constant/config';
import { useMutation, useQueryClient } from 'react-query';
import { Box } from '@mui/system';

function Role() {
  const { roles } = AuthState();

  const [editedRole, seteditedRole] = useState(null);
  const [roleName, setRoleName] = useState(editedRole ? editedRole.name : '');

  const queryClient = useQueryClient();

  const {
    mutate: editRoleMutate,
    // data: deleteData,
    isLoading: editRoleLoading,
  } = useMutation(
    async (variables) => {
      const { data } = await axios.put(`${baseUrl}/api/admin/editRole`, variables);
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allRoles');
        seteditedRole(null);
      },
    }
  );
  const {
    mutate: deleteRoleMutate,
    // data: deleteData,
    isLoading: deleteRoleLoading,
  } = useMutation(
    async (roleId) => {
      const { data } = await axios.delete(`${baseUrl}/api/admin/deleteRole/${roleId}`);
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allRoles');
      },
      onError: (error) => {
        console.log(error)
        if (error.response?.data?.msg) {
          alert(error.response?.data?.msg);
        }
      },
    }
  );

  const handelEditRole = useCallback((role) => {
    seteditedRole((p) => (p ? null : role));
    setRoleName(role.name);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      editRoleMutate({ roleId: editedRole._id, roleName });
    },
    [editRoleMutate, editedRole, roleName]
  );

  return (
    <Page title="Roles">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Roles
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    <TableCell align="right">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles &&
                    roles.map((row, i) => (
                      <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {i + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {editedRole && editedRole._id === row._id ? (
                            <Box component={'form'} onSubmit={handleSubmit} display={'flex'} gridGap="10px">
                              <TextField
                                id="filled-basic"
                                label="Filled"
                                variant="filled"
                                size="small"
                                disabled={editRoleLoading}
                                value={roleName}
                                onChange={(e) => setRoleName(e.target.value)}
                              />
                              {/* <Button variant="contained" size='small' onClick={handleSubmit} disabled={editRoleLoading}>
                                submit
                              </Button> */}
                            </Box>
                          ) : (
                            row.name
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {row.name !== 'admin' ? (
                            <IconButton
                              // color={"primary"}
                              onClick={() => handelEditRole(row)}
                              sx={{ width: 40, height: 40 }}
                            >
                              <Iconify icon="material-symbols:edit" width={20} height={20} />
                            </IconButton>
                            // <EditIcon sx={{ ':hover': { cursor: 'pointer' } }} onClick={() => handelEditRole(row)} />
                          ) : null}
                        </TableCell>
                        <TableCell align="right">
                          {row.name !== 'admin' ? (
                            <IconButton
                              // color={"error"}
                              onClick={() => deleteRoleMutate(row._id)}
                              sx={{ width: 40, height: 40 }}
                            >
                              <Iconify icon="mdi:delete" width={20} height={20} />
                            </IconButton>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

export default Role;
