import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { AuthState } from '../context/auth';

const ProtectedRoute = ({ isAuth, children }) => {
  const token = localStorage.getItem('admin_token');

  if (!token) {
    console.log('...........');
    return <Navigate to="/login" replace />;
  }
  //   return <Navigate to="/dashboard/user" replace />;

  return children;

  // return (
  //   <Route
  //     {...rest}
  //     render={(props) => {
  //       if (!refreshKey) return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
  //       return <Component {...props} />;
  //     }}
  //   />
  // );
};

export default ProtectedRoute;
