import {
  Box,
  Button,
  Card,
  Container,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
// import TopicForm from 'src/components/TopicForm';
import { baseUrl } from '../constant/config';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import DebateForm from '../components/DebateForm';
import DebateRow from '../components/DebateRow';
// import DebateReqModal from 'src/components/DebateReqModal';

const style = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 400,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
};

function Debate() {
  const [editedDebate, setEditedDebate] = useState(null);
  const [open, setOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const queryClient = useQueryClient();

  const { data: debates, isSuccess } = useQuery(
    ['allDebtes'],
    async () => {
      const url = `${baseUrl}/api/admin/getDebates`;
      const { data } = await axios.get(url);
      return data.debates;
    },
    {
      // enabled: !!isAuth,
      // refetchOnWindowFocus: false,
      // refetchOnReconnect: false,
      refetchInterval: 10000
    }
  );

  const { mutate } = useMutation(
    async (variables) => {
      const { data } = await axios.post(`${baseUrl}/api/admin/addDebate`, variables, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    },
    {
      onSuccess: (response) => {
        setOpen(false);
        queryClient.refetchQueries('allDebtes');
        window.alert(response.msg);
      },
      onError: (error) => {
        console.log(error);
        window.alert(error.response?.data?.msg || error.message);
      },
    }
  );

  const {
    mutate: editDebateMutate,
    // data: deleteData,
    isLoading: editRoleLoading,
  } = useMutation(
    async (variables) => {
      console.log(variables.formData);
      const { data } = await axios.post(`${baseUrl}/api/admin/editDebate/${variables.debateId}`, variables.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allDebtes');
        setEditedDebate(null);
        setOpen(false);
      },
      onError: (error) => {
        console.log(error);
        window.alert(error.response.statusText || error.message);
      },
    }
  );

  const {
    mutate: deleteTopic,
    // data: deleteData,
    isLoading: deleteRoleLoading,
  } = useMutation(
    async (debateId) => {
      const { data } = await axios.delete(`${baseUrl}/api/admin/deleteDebate/${debateId}`);
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries('allDebtes');
      },
    }
  );

  const handleOpen = () => {
    setEditedDebate(null);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handelEditDebate = useCallback((plan) => {
    setEditedDebate(plan);
    setOpen(true);
  }, []);

  const handelViewReqs = useCallback((plan) => {
    setEditedDebate(plan);
    setIsView(true);
  }, []);

  return (
    <Page title="Debate">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Debates
          </Typography>
          <Box sx={{ display: 'flex', gridGap: '10px' }}>
            <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              New debate
            </Button>
          </Box>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Edit</TableCell>
                    {/* <TableCell>Requests</TableCell> */}
                    {/* <TableCell>Delete</TableCell> */}
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isSuccess &&
                    debates.map((row, i) => (
                      <DebateRow
                        inc={i}
                        key={i}
                        row={row}
                        count={debates.length}
                        handelEditDebate={handelEditDebate}
                        handelViewReqs={handelViewReqs}
                        deleteTopic={deleteTopic}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>{' '}
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        > */}
        <Dialog onClose={handleClose} open={open}>
          <Box sx={{ m: 0, p: 4, minWidth: 320 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={'center'} mb="10px">
              {!editedDebate ? `Add new` : 'Edit'} debate
            </Typography>
            <Box sx={{ width: '100%' }}>
              <DebateForm mutate={mutate} editedDebate={editedDebate} editDebateMutate={editDebateMutate} />
            </Box>
          </Box>
        </Dialog>
        {/* </Modal> */}
        {/* <DebateReqModal isView={isView} setIsView={setIsView} style={style} editedDebate={editedDebate} /> */}
      </Container>
    </Page>
  );
}

export default Debate;
