import { LoadingButton } from '@mui/lab';
import React from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';

function TopicForm({ mutate, editedTopic, editTopicMutate }) {
    const queryClient = useQueryClient();

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        imageUrl: Yup.string(),
        showOnMenu: Yup.boolean(),
        positionOnMenu: Yup.number().min(1).required('required'),
    });

    const formik = useFormik({
        initialValues: {
            name: editedTopic?.name || '',
            title: editedTopic?.title || '',
            imageUrl: editedTopic?.imageUrl || '',
            description: editedTopic?.description || '',
            showOnMenu: editedTopic?.showOnMenu || false,
            positionOnMenu: editedTopic?.positionOnMenu || '',
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            if (editedTopic) {
                editTopicMutate({ topicId: editedTopic._id, ...values });
            } else {
                mutate(values);
            }
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        label="Name"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                    />
                    <TextField
                        fullWidth
                        label="Title"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                    />

                    <TextField
                        fullWidth
                        label="Description"
                        multiline
                        rows={4}
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                    />
                    <TextField
                        fullWidth
                        label="Image Url"
                        {...getFieldProps('imageUrl')}
                        error={Boolean(touched.imageUrl && errors.imageUrl)}
                        helperText={touched.imageUrl && errors.imageUrl}
                    />
                    <TextField
                        fullWidth
                        type="number"
                        label="Position on menu"
                        {...getFieldProps('positionOnMenu')}
                        error={Boolean(touched.positionOnMenu && errors.positionOnMenu)}
                        helperText={touched.positionOnMenu && errors.positionOnMenu}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                defaultChecked={formik.values.showOnMenu}
                                value={formik.values.showOnMenu}
                                onChange={(e) => formik.setFieldValue('showOnMenu', e.target.checked)}
                            />
                        }
                        label="Show on menu"
                    />
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        {editedTopic ? 'UPDATE' : 'ADD'}
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default TopicForm;
