// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'roles',
    path: '/dashboard/role',
    icon: getIcon('eos-icons:role-binding'),
  },
  {
    title: 'plans',
    path: '/dashboard/plan',
    icon: getIcon('pajamas:planning'),
  },
  {
    title: 'features',
    path: '/dashboard/feature',
    icon: getIcon('mdi:feature-search'),
  },
  {
    title: 'topics',
    path: '/dashboard/topic',
    icon: getIcon('material-symbols:topic'),
  },
  {
    title: 'debates',
    path: '/dashboard/debate',
    icon: getIcon('mdi:account-voice'),
  },
  {
    title: 'notifications',
    path: '/dashboard/notification',
    icon: getIcon('eva:bell-fill'),
  },
  {
    title: 'Banner',
    path: '/dashboard/banner',
    icon: getIcon('ph:flag-banner'),
  },
  {
    title: 'Ads',
    path: '/dashboard/ads',
    icon: getIcon('mdi:google-ads'),
  },
  {
    title: 'Ads Packages',
    path: '/dashboard/ads-packages',
    icon: getIcon('ri:advertisement-line'),
  },
  {
    title: 'About',
    path: '/dashboard/about',
    icon: getIcon('mdi:about'),
  },
  {
    title: 'Terms & Conditions',
    path: '/dashboard/terms-and-conditions',
    icon: getIcon('ic:twotone-receipt-long'),
  },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  // icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
