/* eslint-disable import/no-unresolved */
import {
  Button,
  Card,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { baseUrl } from 'src/constant/config';

function Notification() {
  const queryClient = useQueryClient();

  const { data: debateReqs,refetch} = useQuery(
    ['debate_requests'],
    async () => {
      const url = `${baseUrl}/api/admin/getDebateRequest`;
      const { data } = await axios.get(url);
      return data.requests;
    },
    {
      // refetchOnWindowFocus: false,
      // refetchOnReconnect: false,
      refetchInterval: 5000,
      refetchIntervalInBackground:true
    }
  );
    console.log(debateReqs,"debateReqs")

  const { mutate } = useMutation(
    async (v) => {
      console.log('Data-->', v);
      const { data } = await axios.put(`${baseUrl}/api/admin/updateDebateRequest`, { ...v });
      return data;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries(['debate_requests']);
        queryClient.invalidateQueries('notifications');

        // refetch
        // window.location.reload();
      },
    }
  );

  const { mutate: deletereq, isLoading: deleteIsLoading } = useMutation(
    async (v) => {
      console.log(v,"sdfgndbfhgd")
      const { data: deletedata } = await axios.post(`${baseUrl}/api/admin/getDeleteRequest`, { ...v });
      return deletedata;
    },
    {
      onSuccess: (d) => {
        queryClient.invalidateQueries(['debate_requests']);

      },
      onSettled: () => {
        // window.location.reload('/');
      },
    }
  );
  if (deleteIsLoading) {
    return <p>Loading....</p>;
  }

  return (
    <Page title="Notifications">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Notifications
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {debateReqs?.length > 0 ? (
                    debateReqs.map((dq, i) => (
                      <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: '100%' }}>
                        <TableCell component="td" scope="row">
                          {dq.debateId?.title}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {dq.userId?.firstName} {dq.userId?.lastName}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {dq.accepted ? 'IN' : 'OUT'}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {new Date(dq.createdAt).toLocaleDateString()} {new Date(dq.createdAt).toLocaleTimeString()}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          <Button
                            variant="contained"
                            size="small"
                            style={{ marginRight: '10px', width: '110px' }}
                            color={dq.accepted ? 'error' : 'success'}
                            onClick={() =>
                              mutate({ debateId: dq.debateId._id, userId: dq?.userId?._id, accepted: !dq.accepted })
                            }
                          >
                            {dq?.accepted ? 'REJECT' : 'ACCEPT'}
                          </Button>

                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => deletereq({ id: dq?._id })}
                            style={{ width: '110px', backgroundColor: '#ff9d00' }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell component="td" scope="row">
                      No user requests
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

export default Notification;
